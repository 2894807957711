<template>
  <div class="not-found">
    <h1>404 Not Found</h1>
    <p>Deze pagina bestaat niet, ga terug naar:</p>
    <!-- Go Back to Homepage Button -->
    <router-link to="/" class="back-home-btn">
      <i class="fas fa-home"></i> Home
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}

.not-found h1 {
  font-size: 4rem;
  color: #ff6b6b;
}

.not-found p {
  font-size: 1.5rem;
  color: #333;
}

.back-home-btn {
  background-color: #555;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid white;
  max-width: 200px;
  margin: 20px auto;
  box-sizing: border-box;
  flex-grow: 1;
}

.back-home-btn i {
  font-size: 1.25rem;
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .back-home-btn {
    min-width: 100%;
  }
}
</style>
