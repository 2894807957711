<template>
  <div class="cart-page">
    <h1>Winkelwagen</h1>
    <div v-if="cart.length === 0" class="empty-cart">
      <p>Uw winkelwagen is leeg.</p>
    </div>
    <div v-else class="cart-items">
      <div v-for="(item, index) in cart" :key="index" class="cart-item">
        <img
          v-if="item.product && item.product.image"
          :src="item.product.image"
          :alt="item.product.name"
          class="product-image"
        />
        <div class="item-details">
          <h2>{{ item.product?.name || "Onbekend product" }}</h2>
          <p v-if="item.product?.price">
            Prijs per m²: €{{ item.product.price.toFixed(2) }}
          </p>
          <p>Aantal m²: {{ item.quantity }}</p>
          <p v-if="item.product?.price">
            Totaal: €{{ (item.product.price * item.quantity).toFixed(2) }}
          </p>
        </div>
        <button @click="removeFromCartFunction(index)" class="btn btn-danger">
          Verwijderen
        </button>
      </div>
      <button @click="submitQuote" class="btn btn-primary mt-3">
        Offerte aanvragen
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { getCart, removeFromCart, clearCart } from "@/store/useCart"; // Centralized cart composable
import axios from "axios";

const cart = ref(getCart());

// Watch for cart changes in localStorage
watchEffect(() => {
  cart.value = getCart(); // Update the cart reactively
});

function removeFromCartFunction(index) {
  removeFromCart(index); // Call the centralized remove function
  cart.value = getCart(); // Update the cart reactively after removing
}

async function submitQuote() {
  if (cart.value.length === 0) return alert("Uw winkelwagen is leeg.");
  const contact = {
    name: prompt("Voer uw naam in:"),
    email: prompt("Voer uw e-mail in:"),
    phone: prompt("Voer uw telefoonnummer in:"),
  };

  const requestData = {
    cart: cart.value,
    contact,
  };

  const apiUrl = process.env.VUE_APP_API_URL;

  try {
    await axios.post(`${apiUrl}/api/send-cart-quote`, requestData);
    alert("Offerte aanvraag verzonden!");
    clearCart();
    cart.value = []; // Clear the cart reactively
  } catch (error) {
    alert("Er is iets misgegaan bij het verzenden van uw offerte.");
  }
}
</script>

<style scoped>
.cart-page {
  padding: 20px;
}
.cart-items {
  max-width: 600px;
  margin: 0 auto;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.product-image {
  max-width: 100px;
  border-radius: 5px;
}
.item-details {
  flex-grow: 1;
  margin-left: 20px;
}
</style>
