<template>
  <div class="product-page">
    <h1>Product Details</h1>
    <div class="product-details">
      <img :src="product.image" :alt="product.name" class="product-image" />
      <h2>{{ product.name }}</h2>
      <p class="price">Prijs per m²: €{{ product.price.toFixed(2) }}</p>

      <div class="form-group">
        <label for="quantity">Aantal m²</label>
        <input v-model="quantity" type="number" min="1" id="quantity" />
      </div>

      <p class="total-price">
        Totale prijs: €{{ (product.price * quantity).toFixed(2) }}
      </p>

      <button @click="handleAddToCart" class="btn btn-primary">
        Voeg toe aan winkelwagen
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { addToCart } from "@/store/useCart"; // Import the cart logic

const route = useRoute();

const product = ref({
  id: route.query.id,
  name: route.query.name,
  price: parseFloat(route.query.price),
  image: route.query.image,
});

const quantity = ref(1);

function handleAddToCart() {
  addToCart(product.value, quantity.value); // Use addToCart from useCart.js
  alert("Product is toegevoegd aan de winkelwagen.");
}
</script>

<style lang="scss" scoped>
.product-page {
  padding: 20px;
  text-align: center;
}

.product-details {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.total-price {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #369f73;
}
</style>
