import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { createWebHistory, createRouter } from "vue-router";

import Home from "./views/HomePage.vue";
import CustomerSupport from "./views/CustomerSupport.vue";
import TransportAndCosts from "./views/TransportAndCosts.vue";
import FloorTiles from "./views/FloorTiles.vue";
import WallTiles from "./views/WallTiles.vue";
import TerraceTiles from "./views/TerraceTiles.vue";
import FacadeTiles from "./views/FacadeTiles.vue";
import RouteAndOpeningHours from "./views/RouteAndOpeningHours.vue";
import CarouselComponent from "./components/CarouselComponent.vue";
import QuotePage from "./views/QuotePage.vue";
import QuoteSuccess from "./views/QuoteSuccess.vue";
import ContactSuccess from "./views/ContactSuccess.vue";
import LocationPage from "./views/LocationPage.vue";
import AboutUsPage from "./views/AboutUsPage.vue";
import ContactPage from "./views/ContactPage.vue";
import TermsAndConditionsPage from "./views/TermsAndConditionsPage.vue";
import NotFoundPage from "./views/NotFoundPage.vue";
import CartPage from "./views/CartPage.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/klantenservice", component: CustomerSupport },
  { path: "/transport-en-kosten", component: TransportAndCosts },
  { path: "/vloertegels", component: FloorTiles },
  { path: "/wandtegels", component: WallTiles },
  { path: "/terrastegels", component: TerraceTiles },
  { path: "/geveltegels", component: FacadeTiles },
  { path: "/route-en-openingstijden", component: RouteAndOpeningHours },
  { path: "/offerte", component: QuotePage },
  { path: "/offerte-succes", component: QuoteSuccess },
  { path: "/contact-succes", component: ContactSuccess },
  { path: "/locatie", component: LocationPage },
  { path: "/over-ons", component: AboutUsPage },
  { path: "/contact", component: ContactPage },
  { path: "/algemene-voorwaarden", component: TermsAndConditionsPage },
  { path: "/cart", name: "CartPage", component: CartPage },
  // Other routes go here...
  {
    path: "/:catchAll(.*)", // Catch all undefined routes
    name: "NotFoundPage",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(), // Changed from createMemoryHistory() to createWebHistory()
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

const app = createApp(App).use(router).mount("#app");

app.component("CarouselComponent", CarouselComponent);
